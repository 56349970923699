import { Button, Flex, Table, Text } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'

import useGetAPI from '../../../hooks/useGetAPI'
import TableLoadingSkeleton from '../../../components/TableLoadingSkeleton'

import UserTableHead from './UserTableHead'
import UserTableBody from './UserTableBody'

import {
  NAME_COLUMN,
  REMOVE_COLUMN
} from './Columns'

import './UserTable.css'

const url = `/users/list`

const UserTable = (props) => {
  const {
    request,
    onRemoveUser
  } = props

  const { loading, data } = useGetAPI({ request, url })
  const { users } = data || {}

  const navigate = useNavigate()

  const columns = [
    NAME_COLUMN(),
    REMOVE_COLUMN(),
  ]

  if (loading) return <TableLoadingSkeleton />

  return (
    <div style={{ overflowX: 'auto' }}>
      <Flex
        justifyContent='flex-end'
        alignItems='center'
        wrap='nowrap'
        marginBottom='2.5rem'
      >
      <Button
        variation='primary'
        onClick={() => navigate('/organisation-settings/add-user')}
      >
        Add user
      </Button>
      </Flex>
      <Text
        fontWeight='400'
        fontSize='0.75rem'
        marginBottom='0.5rem'
      >
        All users
      </Text>
      <Table
        className='user-table'
        highlightOnHover={false}
      >
        <UserTableHead columns={columns} />
        <UserTableBody rows={users} onRemoveUser={onRemoveUser} />
      </Table>
    </div>
  )
}

export default UserTable
