import * as Sentry from '@sentry/react'
import { useParams } from 'react-router-dom'
import { Flex } from '@aws-amplify/ui-react'

import ReturnLink from '../../../components/ReturnLink'
import useGetAPI from '../../../hooks/useGetAPI'
import GrievanceForm from './GrievanceForm'
import LoadingSkeleton from './LoadingSkeleton'

const GrievanceShow = (props) => {
  const { request } = props
  const { id } = useParams()

  const url = `/grievances/show/${id}`
  const { loading, data } = useGetAPI({ request, url })
  const grievance = data?.item

  const updateGrievance = (grievance , func) => {
    request.post(`/grievances/update/${id}`, grievance)
    .then((response) => func(response.data))
    .catch((error) => {
      func(error.message)
      Sentry.captureException(error)
    })
  }

  return (
    <Flex direction='column' gap='2rem'>
      <ReturnLink url='/' />
      {loading
        ? <LoadingSkeleton />
        : <GrievanceForm {...grievance} updateGrievance={updateGrievance} />
      }
    </Flex>
  )
}

export default GrievanceShow
