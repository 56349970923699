import { Flex, Heading, Text } from '@aws-amplify/ui-react'
import * as Sentry from '@sentry/react'
import ReturnLink from '../../../components/ReturnLink'

import AddUserForm from './AddUserForm'

const AddUser = (props) => {
  const { request } = props

  const createUser = (newUser, func) => {

    request.post('users/create', newUser)
      .then((response) => func(response.data))
      .catch((error) => {
        func(error.message)
        Sentry.captureException(error)
      })
  }

  return (
    <Flex direction='column' gap='2rem'>
      <ReturnLink url='/organisation-settings' />
      <div>
        <Heading
          level={1}
          style={{
            fontSize: '1.5rem',
            fontWeight: 400
          }}
          marginBottom='0.5rem'
        >
          Add user
        </Heading>
        <Text
          fontSize='1rem'
          maxWidth= '28rem'
        >
          When you add a user, they will get an email invite to Feedback Now and be asked to create a password.
        </Text>
      </div>

      <AddUserForm createUser={createUser} />
    </Flex>
  )
}

export default AddUser
