import { useState } from 'react'
import { Flex, Heading } from '@aws-amplify/ui-react'
import ReturnLink from '../../components/ReturnLink'
import Brand from './Brand'
import RemoveUser from './RemoveUser'
import UserTable from './user-table/UserTable'

const OrganisationSettings = (props) => {
  const { request } = props
  const [remove, setRemove] = useState()

  const onRemoveUser = (email) => {
    setRemove(email)
  }

  const onCancelRemove = () => {
    setRemove()
  }

  if (remove !== undefined) return <RemoveUser request={request} email={remove} onClose={onCancelRemove} />

  return (
    <Flex direction='column' gap='2rem'>
      <ReturnLink url='/' />
      <Heading
        level={1}
        style={{ fontSize: '1.5rem', fontWeight: 400 }}
      >
        Organisation settings
      </Heading>
      <Brand
        request={request}
      />
      <UserTable
        request={request}
        onRemoveUser={onRemoveUser}
      />
    </Flex>
  )
}

export default OrganisationSettings
