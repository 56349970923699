import { Routes, Route } from 'react-router-dom'

import ErrorFallback from './components/error-fallback/ErrorFallback'

import Grievances from './pages/grievances/Grievances'
import GrievanceShow from './pages/grievances/grievance-show/GrievanceShow'
import OrganisationSettings from './pages/organisation-settings/OrganisationSettings'
import AddUser from './pages/organisation-settings/add-user/AddUser'
import ProfileSettings from './pages/profile-settings/ProfileSettings'

export const ROUTES = (props) => {
  const {
    user,
    request,
  } = props

  return (
    <Routes>
      <Route path='*' element={<ErrorFallback status={404} />}/>
      <Route
        path={'/'}
      >
        <Route
          index
          exact
          element={<Grievances request={request} />}
        />
        <Route
          path={'grievance/:id/view'}
          element={<GrievanceShow request={request}/>}
        />
      </Route>
      <Route
        path={'/profile'}
        element={<ProfileSettings user={user} />}
      />
      <Route
        path={'/organisation-settings'}
      >
        <Route
          index
          exact
          element={<OrganisationSettings request={request} />}
        />
        <Route
          path={'add-user'}
          element={<AddUser request={request} />}
        />
      </Route>
    </Routes>
  )
}
