import { Heading } from '@aws-amplify/ui-react'
import GrievanceTable from './grievance-table/GrievanceTable'

const Grievances = (props) => {
  const { request } = props
  return (
    <>
    <Heading
      level={1}
      style={{
        fontSize: '1.5rem',
        fontWeight: 400,
        marginBottom: '2.5rem'
      }}
    >
      Feedback Now: dashboard
    </Heading>

      <GrievanceTable
        request={request}
      />
    </>
  )
}

export default Grievances
