import { Link } from 'react-router-dom'
import { Flex } from '@aws-amplify/ui-react'
import Menu from '../menu/Menu'

import './Header.css'

const Header = (props) => {
  const { signOut } = props

  return (
    <header className='header'>
      <a className='header__skip-link' href='#main'>Skip to content</a>
      <Flex
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        gap='1rem'
        padding='.5rem 0'
        marginBottom='3rem'
        wrap='wrap'
      >
        <Link to='/' className='header__logo'>
          <img
            alt='Ask your team logo'
            src='/askyourteam-logo.svg'
          />
        </Link>
        <Menu signOut={signOut} />
      </Flex>
    </header>
  )
}

export default Header
