import PropTypes from 'prop-types'
import classNames from 'classnames'
import { forwardRef } from 'react'

const CHROME_ANTI_ALIASING_FIX = {
  opacity: '0.999'
}

export const IconWrapper = forwardRef(function IconWrapper(props, ref) {
  const {
    style,
    size,
    color,
    className,
    rotate,
    ...rest
  } = props

  return (
    <svg
      className={classNames('svg-icon', className)}
      ref={ref}
      style={{
        color,
        ...style,
        ...(rotate && { transform: `${style?.transform || ''} rotate(${rotate}deg)` })
      }}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={'0 0 24 24'}
      xmlns={'http://www.w3.org/2000/svg'}
      vectorEffect={'non-scaling-stroke'}
      {...CHROME_ANTI_ALIASING_FIX}
      {...rest}
    />
  )
})

IconWrapper.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

IconWrapper.defaultProps = {
  size: 0
}
