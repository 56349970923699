import { Flex } from '@aws-amplify/ui-react'

import StatusPill from '../../../components/status-pills/StatusPills'
import { STATUS } from '../../../utils/Enum'

const StatusSelector = (props) => {
  const {
    currentStatus,
    onChangeStatus
  } = props

  return (
    <Flex
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      gap='1.5rem'
    >
      <StatusPill
        isSelected={currentStatus === STATUS.in_progress}
        status={STATUS.in_progress}
        readOnly={false}
        onChange={onChangeStatus}
      />
      <StatusPill
        isSelected={currentStatus === STATUS.open}
        status={STATUS.open}
        readOnly={false}
        onChange={onChangeStatus}
      />
      <StatusPill
        isSelected={currentStatus === STATUS.closed}
        status={STATUS.closed}
        readOnly={false}
        onChange={onChangeStatus}
      />
    </Flex>
  )
}

export default StatusSelector
