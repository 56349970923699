import { TableRow, TableCell } from '@aws-amplify/ui-react'
import { Link } from 'react-router-dom'

import { SVGMinus } from '../../../components/icons/SVGMinus'
import StatusPill from '../../../components/status-pills/StatusPills'
import { CATEGORIES } from '../../../utils/Enum'

const GrievanceTableRow = (props) => {
  const {
    sort_id,
    company,
    received_at,
    category,
    contactable,
    status
  } = props

  const categoryDisplay = CATEGORIES.filter(({ value }) => category.includes(value))
  const date_received = new Date(received_at)

  return (
    <TableRow>
      <TableCell className='grievance-table__cell--supplier_name'>
        <Link to={`/grievance/${sort_id}/view`}>{company}</Link>
      </TableCell>
      <TableCell>{sort_id}</TableCell>
      <TableCell>{date_received.toLocaleDateString()}</TableCell>
      <TableCell>
        {category.length
          ? categoryDisplay?.map(({ label }) => label).join(', ')
          : <SVGMinus size={16} style={{ verticalAlign: 'middle'}} />
        }
      </TableCell>
      <TableCell className='grievance-table__cell--anonymity'>
        {contactable ? 'No' : 'Yes'}
      </TableCell>
      <TableCell className='grievance-table__cell--status'>
        <StatusPill status={status} readOnly={true} />
      </TableCell>
    </TableRow>
  )
}

export default GrievanceTableRow
