import { Link } from 'react-router-dom'
import { SVGArrowSolid } from './icons/SVGArrowSolid'

const ReturnLink = (props) => {
  const { url, onClick } = props
  return (
    <span>
      <Link
        style={{
          textDecoration: 'none'
        }}
        className='return-link'
        to={url}
        onClick={onClick}
      >
        <SVGArrowSolid
          size={16}
          rotate={90}
          style={{
            marginRight: 8,
            verticalAlign: 'middle'
          }}
        />
        Back
      </Link>
    </span>
  )
}

ReturnLink.defaultProps = {
  onClick: () => {}
}


export default ReturnLink
