import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TextField, Button, Flex } from '@aws-amplify/ui-react'

import ToastNotification from '../../../components/toast-notification/ToastNotification'

const AddUserForm = (props) => {
  const { createUser } = props
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const navigate = useNavigate()

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const onClear = () => {
    setName('')
    setEmail('')

    navigate('/organisation-settings')
  }

  const setNewUser = (response) => {
    setLoading(false)
    const { user, error } = response

    if (!error) {
      onClear()
      ToastNotification({ message: `${user.email} has been created`})
    }
  }

  const onCreateUser = async (e) => {
    e.preventDefault()
    setLoading(true)

    const newUser = {
      name: name,
      email: email
    }

    createUser(newUser, setNewUser)
  }

  return (
    <form onSubmit={onCreateUser}>
      <TextField
        fontSize='0.75rem'
        fontWeight='400'
        value={name}
        label='Name'
        onChange={handleNameChange}
        isReadOnly={loading}
        isRequired={true}
        errorMessage='You need to enter a name'
        marginBottom='1.5rem'
        maxWidth= '28rem'
      />
      <TextField
        fontSize= '0.75rem'
        fontWeight='400'
        value={email}
        label='Email'
        onChange={handleEmailChange}
        isReadOnly={loading}
        isRequired={true}
        errorMessage='You need to enter an email'
        marginBottom='5rem'
        maxWidth= '28rem'
      />
      <Flex
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        wrap='nowrap'
        gap='2.5rem'
      >
        <Button
          isDisabled={loading}
          onClick={onClear}
        >
          Cancel
        </Button>
        <Button
          width='4.75rem'
          height='2.65rem'
          variation='primary'
          type='submit'
          isLoading={loading}
        >
          Add
        </Button>
     </Flex>
    </form>
  )
}

export default AddUserForm
