import { Flex, Heading } from '@aws-amplify/ui-react'
import { Auth } from '@aws-amplify/auth'
import { useCallback, useState } from 'react'

import ReturnLink from '../../components/ReturnLink'
import ProfileDetailsForm from './ProfileDetailsForm'
import ValidationForm from './ValidationForm'
import UpdatePasswordForm from './UpdatePasswordForm'

const ProfileSettings = ({ user }) => {
  const {
    email,
    name,
  } = user.attributes

  const [emailChanged, setEmailChanged] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [currentName, setCurrentName] = useState(name)
  const [currentEmail, setCurrentEmail] = useState(email)

  const getNonCachedUser = useCallback(
    async () => {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
      setCurrentEmail(user?.attributes.email)
      setCurrentName(user?.attributes.name)
      return user
    },
    []
  )

  const showResetPassword = useCallback(
    (value) => {
      setResetPassword(value)
    },
    []
  )

  const validateEmail = async (value) => {
    await getNonCachedUser()
    setEmailChanged(value)
  }

  if (resetPassword) return (
    <Flex direction='column' gap='2rem'>
     <ReturnLink onClick={() => showResetPassword(false)} />
      <Heading
        level={1}
        style={{ fontSize: '1.5rem', fontWeight: 400 }}
      >
        Profile settings
      </Heading>

      <UpdatePasswordForm
        showResetPassword={showResetPassword}
        getNonCachedUser={getNonCachedUser}
      />
    </Flex>
  )

  return (
    <Flex direction='column' gap='2rem'>
      <ReturnLink url='/' />
      <Heading
        level={1}
        style={{ fontSize: '1.5rem', fontWeight: 400 }}
      >
        Profile settings
      </Heading>

      {emailChanged ? (
        <ValidationForm validateEmail={validateEmail} />
      ) : (
        <ProfileDetailsForm
          currentEmail={currentEmail}
          currentName={currentName}
          getNonCachedUser={getNonCachedUser}
          validateEmail={validateEmail}
          showResetPassword={showResetPassword}
        />
      )}
    </Flex>
  )
}

export default ProfileSettings
