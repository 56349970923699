export const ID_COLUMN = () => ({
  key: 'id',
  value: 'ID',
})

export const SUPPLIER_NAME_COLUMN = () => ({
  key: 'supplier_name',
  value: 'Supplier',
})

export const DATE_RECEIVED_COLUMN = () => ({
  key: 'date_received',
  value: 'Date received',
})

export const ANONYMITY_COLUMN = () => ({
  key: 'anonymous',
  value: 'Anonymous',
})

export const STATUS_COLUMN = () => ({
  key: 'status',
  value: 'Status',
})

export const CATEGORY_COLUMN = () => ({
  key: 'category',
  value: 'Category',
})
