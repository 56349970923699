import { TableRow, TableCell } from '@aws-amplify/ui-react'
import { SVGTrashCan } from '../../../components/icons/SVGTrashCan'

const UserTableRow = (props) => {
  const {
   name,
   email,
   onRemoveUser
  } = props

  return (
    <TableRow>
      <TableCell className='user-table__cell--name'>{name}</TableCell>
      <TableCell className='user-table__cell--remove' onClick={() => onRemoveUser(email)}>
        <SVGTrashCan size={16} />
      </TableCell>
    </TableRow>
  )
}

export default UserTableRow
