import { forwardRef } from 'react'
import { IconWrapper } from './IconWrapper'

export const SVGArrowSolid = forwardRef(function SVGArrowSolid(props, ref) {
  return (
    <IconWrapper ref={ref} data-component={'svg-arrow-solid'} {...props} >
      <g fill={'none'} stroke={'currentColor'} >
        <polyline
          points={'17 18.5 12 23.5 7 18.5'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <line
          x1={'12'} y1={'23.5'} x2={'12'} y2={'0.5'}
          strokeLinecap={'round'} strokeLinejoin={'round'}
        />
      </g>
    </IconWrapper>
  )
})
