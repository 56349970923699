import { forwardRef } from 'react'
import { IconWrapper } from './IconWrapper'

export const SVGTrashCan = forwardRef(function SVGTrashCan(props, ref) {
  return (
    <IconWrapper ref={ref} data-component={'svg-trash-can'} {...props} >
      <g fill={'currentColor'} >
        <path d={'M23.5,7H.5a.5.5,0,0,1-.5-.5A.5.5,0,0,1,.5,6h23a.5.5,0,0,1,.5.5A.5.5,0,0,1,23.5,7Z'} />
        <path d={'M18.5,24H5.5A2.5,2.5,0,0,1,3,21.5V6.5a.5.5,0,0,1,1,0v15A1.5,1.5,0,0,0,5.5,23h13A1.5,1.5,0,0,0,20,21.5V6.5a.5.5,0,0,1,1,0v15A2.5,2.5,0,0,1,18.5,24Z'} />
        <path d={'M21.5,7a.5.5,0,0,1-.5-.5v-1A1.5,1.5,0,0,0,19.5,4H4.5A1.5,1.5,0,0,0,3,5.5v1a.5.5,0,0,1-1,0v-1A2.5,2.5,0,0,1,4.5,3h15A2.5,2.5,0,0,1,22,5.5v1A.5.5,0,0,1,21.5,7Z'} />
        <path d={'M15,4a.5.5,0,0,1-.5-.5,2.5,2.5,0,0,0-5,0,.5.5,0,0,1-1,0,3.5,3.5,0,0,1,7,0A.5.5,0,0,1,15,4Z'} />
        <path d={'M12,20a.5.5,0,0,1-.5-.5V10a.5.5,0,0,1,1,0v9.5A.5.5,0,0,1,12,20Z'} />
        <path d={'M16.5,20a.5.5,0,0,1-.5-.5V10a.5.5,0,0,1,1,0v9.5A.5.5,0,0,1,16.5,20Z'} />
        <path d={'M7.5,20a.5.5,0,0,1-.5-.5V10a.5.5,0,0,1,1,0v9.5A.5.5,0,0,1,7.5,20Z'} />
      </g>
    </IconWrapper>
  )
})
