import './LoadingBar.css'

const LoadingBar = ({ height, width, style, ...props }) => (
  <div
    className={'skeleton-loading-bar'}
    style={{ height, width, ...style }}
    {...props}
  />
)

export default LoadingBar
