import { useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Button, Flex, PasswordField } from '@aws-amplify/ui-react'

import ToastNotification from '../../components/toast-notification/ToastNotification'

const UpdatePasswordForm = (props) => {
  const {
    getNonCachedUser,
    showResetPassword
  } = props

  const [loading, setLoading] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [currentPasswordError, setCurrentPasswordError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false)


  const handleCurrentPasswordChange= (event) => {
    setCurrentPassword(event.target.value)
  }

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value)
  }

  const onCancel = () => {
    setCurrentPassword('')
    setNewPassword('')

    showResetPassword(false)
  }

  const onUpdatePassword = async (e) => {
    e.preventDefault()
    setLoading(true)
    setCurrentPasswordError(false)
    setNewPasswordError(false)

    const user = await getNonCachedUser()

    await Auth.changePassword(user, currentPassword, newPassword).then(() => {
      setLoading(false)
      ToastNotification({ message: 'Your password has been updated' })
    }).catch((error) => {
      setLoading(false)
      if (error.message === 'Incorrect username or password.') {
        return setCurrentPasswordError(true)
      }

      if (error.message.includes('Password did not conform with policy:')) {
        return setNewPasswordError(true)
      }

    })
  }

  return (
    <form onSubmit={onUpdatePassword}>
      <PasswordField
        fontSize='0.75rem'
        fontWeight='400'
        value={currentPassword}
        label='Current password'
        onChange={handleCurrentPasswordChange}
        isReadOnly={loading}
        isRequired={true}
        hasError={currentPasswordError}
        errorMessage='Your password does not match our records.'
        marginBottom='1.5rem'
        maxWidth= '28rem'
      />
      <PasswordField
        fontSize= '0.75rem'
        fontWeight='400'
        value={newPassword}
        label='New password'
        onChange={handleNewPasswordChange}
        isReadOnly={loading}
        isRequired={true}
        hasError={newPasswordError}
        errorMessage='Your password must have at least 1 lowercase and uppercase letter, symbol a number and be at least 8 characters.'
        marginBottom='.8rem'
        maxWidth= '28rem'
      />
      <div
        style={{
          fontSize: '0.75rem',
          fontWeight: '300',
          marginBottom: '5rem',
          maxWidth: '28rem'
        }}
      >
        Your password must have at least 1 lowercase and uppercase letter, symbol and number.
      </div>

      <Flex
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        wrap='nowrap'
        gap='2.5rem'
      >
        <Button
          isDisabled={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          width='4.75rem'
          height='2.65rem'
          variation='primary'
          type='submit'
          isLoading={loading}
        >
          Save
        </Button>
     </Flex>
    </form>
  )
}

export default UpdatePasswordForm
