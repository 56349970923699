import { Component } from 'react'
import * as Sentry from '@sentry/react'

import './ErrorFallback.css'

export class ErrorFallback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      hasError: false,
      status: undefined
    }

    this.providedValue = {
      setStatus: this.setStatus
    }
  }

  static getDerivedStateFromError = error => ({
    error,
    ...error.status && ({ status: error.status }),
    hasError: true
  })

  componentDidCatch = (error, errorInfo) => {
    if (process.env.NODE_ENV === 'development') return
    Sentry.withScope(scope => {
      Sentry.addBreadcrumb({
        category: 'boundary',
        message: 'Error boundary was displayed',
      })
      scope.setTag('error-boundary', true)
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  render() {
    const errorStatus = this.props.status ? this.props.status : this.state.status

    return (
      <ErrorRenderer
        status={errorStatus}
      />
    )
  }
}

const ErrorRenderer = ({ status }) => {
  if (status === 404) {
    return (
      <div role='alert' className='error-boundary'>
        <h1
          className='error-boundary__title'
        >
          Sorry, we can’t find that page
        </h1>
        <p
          className='error-boundary__error-code'
        >
          Error: 404 Page not found
        </p>
        <p>To find what you need, please:</p>
        <ul className='error-boundary__text'>
          <li>check the spelling of the URL (website address), or</li>
          <li>try using the menu options.</li>
        </ul>
        <p><strong style={{ fontWeight: 400 }}>Still can’t find what you need?</strong> Contact us at <a href='mailto:support@askyourteam.com'>support@askyourteam.com</a>.</p>
      </div>
    )
  }

  if (status === 403) {
    return (
      <div role='alert' className='error-boundary'>
        <h1
          className='error-boundary__title'
        >
          Sorry, you don’t have permission to access this page
        </h1>
        <p className='error-boundary__error-code'>
          Error: 403 Forbidden
        </p>
        <p>If you need access, please contact us at <a href='mailto:support@askyourteam.com'>support@askyourteam.com</a>.</p>
      </div>
    )
  }

  return (
    <div role='alert' className='error-boundary'>
      <h1
        className='error-boundary__title'
      >
        Something has gone wrong
      </h1>
      <p className='error-boundary__error-code'>
        Error: {status}
      </p>
      <p className='error-boundary__text'>Sorry about that! We’ve got our team working to fix it.</p>
      <p className='error-boundary__text'>Please wait a few minutes, then try reloading the page.</p>
      <p>
        <strong style={{ fontWeight: 400 }}>Still getting an error? </strong>
        Contact us at <a href='mailto:support@askyourteam.com'>support@askyourteam.com</a>.
      </p>
    </div>
  )
}

export default ErrorFallback
