import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

const useIdle = ({ idleTime }) => {
  const [isIdle, setIsIdle] = useState()

  const handleOnIdle = () => {
    setIsIdle(true)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500
  })

  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle
  }
}

export default useIdle
