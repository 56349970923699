import LoadingBar from './loading-bar/LoadingBar'

const TableLoadingSkeleton = () => {
  return (
    <div>
      <LoadingBar width={'100%'} height={32} style={{ marginBottom: '0.5rem' }} />
      <LoadingBar width={'100%'} height={32} style={{ marginBottom: '0.5rem' }} />
      <LoadingBar width={'100%'} height={32} style={{ marginBottom: '0.5rem' }} />
      <LoadingBar width={'100%'} height={32} style={{ marginBottom: '0.5rem' }} />
      <LoadingBar width={'100%'} height={32} />
    </div>
  )
}

export default TableLoadingSkeleton
