import * as Sentry from '@sentry/browser'

/* eslint-disable no-console */
const session__logging_level = localStorage.getItem('__logging_level')
if (session__logging_level) window.__logging_level = session__logging_level

export class Log {

  static funcWrapper = (func, level) => {
    if (window.__logging_level !== session__logging_level) localStorage.setItem('__logging_level', window.__logging_level)
    if (window.__logging_level) return func
    if (process.env.JS_LOGGER === '1') return func
    if (level === 1) return func
    return () => {}
  }

  static get log() { return this.funcWrapper(console.log) }
  static get warn() { return this.funcWrapper(console.warn) }
  static get error() { return this.funcWrapper(console.error, 1) }

  static get trace() { return this.funcWrapper(console.trace) }

  static get table() { return this.funcWrapper(console.table) }

  static get time() { return this.funcWrapper(console.time) }
  static get timeLog() { return this.funcWrapper(console.timeLog) }
  static get timeEnd() { return this.funcWrapper(console.timeEnd) }

  static get group() { return this.funcWrapper(console.group) }
  static get groupEnd() { return this.funcWrapper(console.groupEnd) }
  static get groupCollapsed() { return this.funcWrapper(console.groupCollapsed) }

  static get groupLog() {
    return this.funcWrapper((title, ...args) => {
      console.group(title)
      for(let i of args) {
        console.log(i)
      }
      console.groupEnd()
    })
  }

  static get groupFunc() {
    return this.funcWrapper((title, func) => {
      console.group(title)
      func()
      console.groupEnd()
    })
  }

  static get field() {
    return this.funcWrapper((obj) => {
      for (let key in obj) {
        console.log(`%c${key}`, 'color: purple', obj[key])
      }
    })
  }

  static breadcrumb(category, message) {
    Sentry.addBreadcrumb({ category, message: String(message) })
  }
}

export default Log
