import { useState } from 'react'
import { Auth } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { TextField, Button, Flex } from '@aws-amplify/ui-react'

const ValidationForm = ({ validateEmail }) => {
  const [validationCode, setValidationCode] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const handleVerificationCodeChange = (event) => {
    setValidationCode(event.target.value)
  }

  const verifyEmailValidationCode = async (event) => {
    event.preventDefault()
    setLoading(true)
    await Auth.verifyCurrentUserAttributeSubmit('email', validationCode)
    .then(() => {
      setLoading(false)
      validateEmail(false)

    }).catch((error) => {
      setLoading(false)
      setError(error.message)
      Sentry.captureException(error)
    })
  }

  return (
    <form onSubmit={verifyEmailValidationCode}>
      <TextField
        label='Verification code (sent to your new email)'
        onChange={handleVerificationCodeChange}
        isReadOnly={loading}
        isRequired={true}
        errorMessage={error}
        hasError={error !== undefined}
        marginBottom='5rem'
      />
      <Flex
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        wrap='nowrap'
        gap='2.5rem'
      >
      <Button
        width='4.8rem'
        height='2.65rem'
        isLoading={loading}
        variation='primary'
        type='submit'
      >
        Verify
      </Button>
      </Flex>
    </form>
  )
}

export default ValidationForm
