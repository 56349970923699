import { forwardRef } from 'react'

import './SVGDots.css'

export const SVGDots = forwardRef(function SVGDots({ size, color, ...props }, ref) {
  const width = size*5
  const y = size/2
  const margin = size/2

  return (
    <svg
      className={'svg-icon svg-icon--loading--dots'}
      width={`${width}px`}
      height={`${size}px`}
      x={'0px'}
      y={'0px'}
      viewBox={`0 0 ${width} ${size}`}
      {...props}
      ref={ref}
    >
      <circle
        className={'svg-icon--loading--dots__fill svg-icon--loading--dots__dot svg-fill'}
        cx={margin}
        cy={y}
        r={size/2}
        fill={color}
      />
      <circle
        className={'svg-icon--loading--dots__fill svg-icon--loading--dots__dot svg-fill'}
        cx={width/2}
        cy={y}
        r={size/2}
        fill={color}
      />
      <circle
        className={'svg-icon--loading--dots__fill svg-icon--loading--dots__dot svg-fill'}
        cx={width - margin}
        cy={y}
        r={size/2}
        fill={color}
      />
    </svg>
  )
})

SVGDots.defaultProps = {
  size: 6,
  color: 'currentColor'
}
