import { Flex } from '@aws-amplify/ui-react'
import LoadingBar from '../../../components/loading-bar/LoadingBar'

const LoadingSkeleton = () => {
  return (
    <div>
      <Flex
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        gap='1rem'
        marginBottom='5rem'
      >
        <LoadingBar width={'6rem'} height={24} />
        <LoadingBar width={'6rem'} height={24} />
        <LoadingBar width={'6rem'} height={24} />
      </Flex>
      <LoadingBar width={'100%'} height={42} style={{ marginBottom: '2.5rem' }} />
      <LoadingBar width={'100%'} height={42} style={{ marginBottom: '2.5rem' }} />
      <LoadingBar width={'100%'} height={88} style={{ marginBottom: '2.5rem' }} />
      <LoadingBar width={'100%'} height={88} />
    </div>
  )
}

export default LoadingSkeleton
