import { useCallback, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

const useGetAPI = ({ request, url, inView }) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(
    () => {

      request
        .get(url)
        .then((response) => setData(response.data))
        .catch((error) => {
          setError(error.message)
          Sentry.captureException(error.message)
        })
        .finally(() => setLoading(false))
    },
    [request, url]
  )

  const fetchMore = useCallback(
      async () =>  {
      if (!data?.last_evaluated_key) return

      try {
        const response = await request.get(
          url,
          {
            params: { last_evaluated_key: data?.last_evaluated_key }
          })

        const newItems = [...data.items, ...response.data.items]
        setData({ items: newItems })

      } catch (error) {
        setError(error)
        Sentry.captureException(error)
      }
    },
    [request, url, data]
  )

  useEffect(
    () => {
      if (data && inView && data?.last_evaluated_key) fetchMore()
    },
    [data, inView, fetchMore]
  )

  return { data, loading, error }
}

export default useGetAPI
