import { Text } from '@aws-amplify/ui-react'
import LoadingBar from '../../components/loading-bar/LoadingBar'

import useGetAPI from '../../hooks/useGetAPI'
const url = `/brands/show`

const Brand = (props) => {
  const { request } = props
  const { loading, data } = useGetAPI({ request, url })

  const brand = data?.item || {}

  if (loading) return (<LoadingBar width={'28rem'} height={42} />)

  return (
    <Text fontSize='1.25rem'>{brand.brand_name}</Text>
  )
}

export default Brand
