import { forwardRef } from 'react'
import { IconWrapper } from './IconWrapper'

export const SVGMinus = forwardRef(function SVGMinus(props, ref) {
  return (
    <IconWrapper ref={ref} data-component={'svg-minus'} {...props} >
      <g fill={'currentColor'} >
        <path d={'M20.18,12.3a.42.42,0,0,1-.3.13H4.12a.43.43,0,1,1,0-.86H19.88a.43.43,0,0,1,.43.43A.4.4,0,0,1,20.18,12.3Z'} />
      </g>
    </IconWrapper>
  )
})
