import { TableHead, TableRow, TableCell } from '@aws-amplify/ui-react'

const UserTableHead = (props) => {
  const { columns } = props

  return (
    <TableHead>
      <TableRow className='user-table__head'>
        {columns.map(({ key, value}) => (
          <TableCell key={key} as='th' className={`user-table__cell--${key}`}>
            {value}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default UserTableHead
