import { TableBody } from '@aws-amplify/ui-react'
import GrievanceTableRow from './GrievanceTableRow'

const GrievanceTableBody = (props) => {
  const { rows } = props

  return (
    <TableBody>
      {rows.map(({
        sort_id,
        company,
        received_at,
        category,
        contactable,
        status
      }) => (
        <GrievanceTableRow
          key={sort_id}
          sort_id={sort_id}
          company={company}
          received_at={received_at}
          category={category}
          contactable={contactable}
          status={status}
        />
      ))}
    </TableBody>
  )
}

export default GrievanceTableBody
