import { useMemo } from 'react'
import axios from 'axios'
import { Amplify } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import * as Sentry from '@sentry/react'
import './config/sentry'

import { COGNITO } from './config/aws'

import { BASE_API_URL } from './utils/Enum'

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/error-fallback/ErrorFallback'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import useIdle from './hooks/useIdleTimer'
import { ROUTES } from './Routes'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
})


const App = ({ user, signOut }) => {
  const token = user.signInUserSession.idToken.jwtToken
  Sentry.setUser({ email: user.attributes.email })
  const request = useMemo(
    () =>
      axios.create({
        baseURL: BASE_API_URL,
        headers: {
          'Authorization': `${token}`
        }
    }),
    [token]
  )

  const { isIdle } = useIdle({ idleTime: 30 })

  if (isIdle) signOut()

  return (
    <div className='container'>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Header
          signOut={signOut}
        />
        <main id='main' className='main'>
          {ROUTES({ user, request })}
          <ToastContainer />
        </main>
        <Footer />
      </ErrorBoundary>
    </div>
  )
}

export default withAuthenticator(App, { hideSignUp: true })
