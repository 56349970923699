import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import Log from '../utils/Log'

const getFullUTCTimestamp = (date) => {
  const pad = (number, zeros = 2) => (`${new Array(zeros).fill(0)}${number}`).slice(-zeros)
  const dates = [
    pad(date.getUTCFullYear(), 4),
    pad(date.getUTCMonth() + 1),
    pad(date.getUTCDate()),
  ].join('-')
  const time = [
    pad(date.getUTCHours()),
    pad(date.getUTCMinutes()),
    pad(date.getUTCSeconds()),
  ].join(':')
  return `${dates}T${time}.${pad(date.getMilliseconds(), 3)}Z`
}

Sentry.init({
  dsn: 'https://6f547e5cd7c64478bb30338df0854001@o304579.ingest.sentry.io/4504613265604608',
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.2,
  beforeBreadcrumb (breadcrumb, hint) {
    if (breadcrumb.category === 'xhr') {
      const { xhr } = hint
      const now = new Date()
      breadcrumb.data = {
        ...breadcrumb.data,
        start: getFullUTCTimestamp(new Date(xhr.loadstartTime)),
        end: getFullUTCTimestamp(now),
        millisecondsWaitingForResponse: now.valueOf() - xhr.loadstartTime
      }
    }
    return breadcrumb
  },
  beforeSend: (event, hint) => {
    const isDev = ['development', 'test'].includes(process.env.NODE_ENV)
    if (isDev) {
      if (event.exception) {
        console.error(hint.originalException) // eslint-disable-line no-console
        console.log('Sentry', event, hint) // eslint-disable-line no-console
      }
      return
    }
    return event
  },
  integrations: [
    new Sentry.Integrations.Breadcrumbs({ console: false }),
    new Integrations.ExtraErrorData(),
    new TracingIntegrations.BrowserTracing()
  ],
  ignoreErrors: [
    'Non-Error promise rejection captured'
  ]
})

window.Promise._unhandledRejectionFn = error => Sentry.captureException(error)
window.Promise = class extends Promise {
  constructor(...args) {
    return super(...args) // eslint-disable-line constructor-super
  }
  catch(func, ...args) {
    let loggingFunc = (error = {}, ...args) => {
      Log.warn('Promise caught', error)
      Log.breadcrumb('Promise caught', error.message)
      return func(error, ...args)
    }
    return super.catch(loggingFunc, ...args)
  }
}
