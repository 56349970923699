import classNames from 'classnames'
import './StatusPills.css'

const StatusPill = (props) => {
  const {
    status,
    isSelected,
    readOnly,
    onChange
  } = props

  const statusName = status.replace('_', ' ')

  const onClick = (e) => {
    e.preventDefault()
    onChange(status)
  }

  if (readOnly) return (
    <p className={`status-pill status-pill--${status} status-pill--active`}>{statusName}</p>
  )

  return (
    <button
      className={
        classNames(
          `status-pill status-pill--button status-pill--${status}`,
          {
            [`status-pill--active`]: isSelected,
          }
        )
      }

      onClick={onClick}
    >
      {statusName}
    </button>
  )
}

export default StatusPill

StatusPill.defaultProps = {
  onChange: () => {}
};
