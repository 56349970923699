import { Flex, Table } from '@aws-amplify/ui-react'
import { useInView } from 'react-intersection-observer'
import { COLOR } from '../../../utils/Enum'
import { SVGDots } from '../../../components/icons/SVGDots'

import useGetAPI from '../../../hooks/useGetAPI'

import TableLoadingSkeleton from '../../../components/TableLoadingSkeleton'
import GrievanceTableHead from './GrievanceTableHead'
import GrievanceTableBody from './GrievanceTableBody'

import {
  ID_COLUMN,
  SUPPLIER_NAME_COLUMN,
  DATE_RECEIVED_COLUMN,
  ANONYMITY_COLUMN,
  STATUS_COLUMN,
  CATEGORY_COLUMN
} from './Columns'

import './GrievanceTable.css'
import EmptyView from './EmptyView'

const url = '/grievances/list'

const GrievanceTable = (props) => {
  const { request } = props
  const { ref, inView } = useInView({ threshold: 0.9 })
  const { loading, data } = useGetAPI({ request, url, inView })

  const rows = data?.items || []
  const loadMore = data?.last_evaluated_key

  const columns = [
    SUPPLIER_NAME_COLUMN(),
    ID_COLUMN(),
    DATE_RECEIVED_COLUMN(),
    CATEGORY_COLUMN(),
    ANONYMITY_COLUMN(),
    STATUS_COLUMN(),
  ]

  if (loading) return <TableLoadingSkeleton />
  if (rows.length === 0) return <EmptyView />

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        className='grievance-table'
        highlightOnHover={false}
      >
        <GrievanceTableHead columns={columns} />
        <GrievanceTableBody rows={rows} />
      </Table>
      {loadMore && (
        <Flex
          ref={ref}
          inview={inView.toString()}
          alignItems='center'
          justifyContent='center'
          margin='1rem 0'
        >
          <SVGDots color={COLOR.PRIMARY} />
        </Flex>
      )}
    </div>
  )
}

export default GrievanceTable
