import { TableBody } from '@aws-amplify/ui-react'
import UserTableRow from './UserTableRow'

const UserTableBody = (props) => {
  const { rows, onRemoveUser } = props

  return (
    <TableBody>
      {rows.map(({
        name,
        email
      }) => (
        <UserTableRow
          key={email}
          email={email}
          name={name}
          onRemoveUser={onRemoveUser}
        />
      ))}
    </TableBody>
  )
}

export default UserTableBody
