import { TableHead, TableRow, TableCell } from '@aws-amplify/ui-react'

const GrievanceTableHead = (props) => {
  const { columns } = props

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ key, value }) => (
          <TableCell key={key} as='th' className={`grievance-table__cell--${key}`}>
            {value === 'Supplier' ? 'Business' : value}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default GrievanceTableHead
