import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import './Menu.css'

const Menu = (props) => {
  const { signOut } = props

  const location = useLocation()
  const { pathname } = location

  const profileActive = pathname.includes('/profile')
  const orgSettingsActive = pathname.includes('/organisation-settings')
  const homeActive = !profileActive && !orgSettingsActive

  return (
    <nav className='main-menu'>
      <ul>
        <li className='main-menu__item'>
          <Link to={'/'}
            className={
              classNames(
                'main-menu__link',
                {
                  'main-menu__link--active':  homeActive
                }
              )
            }
          >
            Home
          </Link>
        </li>
        <li className='main-menu__item'>
          <Link to={'/profile'}
            className={
              classNames(
                'main-menu__link',
                {
                  'main-menu__link--active': profileActive
                }
              )
            }
          >
            Profile
          </Link>
        </li>
        <li className='main-menu__item'>
          <Link to={'/organisation-settings'}
            className={
              classNames(
                'main-menu__link',
                {
                  'main-menu__link--active': orgSettingsActive
                }
              )
            }
          >
            Organisation settings
          </Link>
        </li>
        <li className='main-menu__item main-menu__item--sign-out'>
          <Link
            onClick={signOut}
            className='main-menu__link'
          >
            Log out
          </Link>
        </li>
      </ul>
    </nav>

  )
}

export default Menu
