
import './Footer.css'

const Footer = () => {

  return (
    <footer className='footer'>
      <p className='footer__text'>
        Powered by
      </p>
      <img
        className='footer__logo'
        alt='Askyourteam logo'
        src='/askyourteam-logo.svg'
      />
    </footer>
  )
}

export default Footer
