import { useState } from 'react'
import { TextAreaField, Button, Flex, TextField, Heading } from '@aws-amplify/ui-react'

import ToastNotification from '../../../components/toast-notification/ToastNotification'

import StatusSelector from './StatusSelector'
import CategorySelector from './CategorySelector'

import './GrievanceForm.css'

const GrievanceForm = (props) => {
  const {
    sort_id,
    details,
    category,
    submitted_by,
    contact,
    company,
    contactable,
    status,
    notes,
    updateGrievance
  } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [detailsChanged, setDetailsChanged] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(status)
  const [selectedCategories, setSelectedCategories] = useState(category)
  const [currentNotes, setCurrentNotes] = useState(notes)

  const onChangeStatus = (value) => {
    setCurrentStatus(value)
    setDetailsChanged(true)
  }

  const onUpdateCategories = (e) => {
    const value = e.target.value
    setDetailsChanged(true)

    if (selectedCategories.includes(value)) {

      const updatedSelectedCategories = selectedCategories.filter(category => {
        if (category !== value) {
          return category
        }
        return null
      })
      setSelectedCategories(updatedSelectedCategories)

    } else {

      setSelectedCategories([...selectedCategories, value])
    }
  }

  const handleNoteChange = (event) => {
    setCurrentNotes(event.target.value)
    setDetailsChanged(true)
  }

  const setUpdated = (response) => {
    const { error } = response
    setLoading(false)
    if (!error) {
      ToastNotification({ message: 'Feedback has been updated'})
    }
  }

  const onUpdateGrievance = async (event) => {
    event.preventDefault()
    if (selectedCategories.length === 0) return setError(true)
    setError(false)
    setLoading(true)
    const params = {
      notes: currentNotes,
      status: currentStatus,
      category: selectedCategories
    }

    updateGrievance(params, setUpdated)
  }

  return (
    <form className='grievance-form' onSubmit={onUpdateGrievance}>
      <Flex
        direction='column'
        gap='2.5rem'
      >
        <Flex
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          gap='1rem'
          marginBottom='2.5rem'
          wrap='wrap'
        >
          <Heading
            level={1}
            style={{ fontSize: '1.5rem', fontWeight: 400 }}
          >
            Feedback ID: {sort_id}
          </Heading>
          <StatusSelector
            currentStatus={currentStatus}
            onChangeStatus={onChangeStatus}
          />
        </Flex>
        {contactable && (
          <>
            <TextField
              fontSize= '0.75rem'
              fontWeight='400'
              value={submitted_by}
              label='Feedback submitted by'
              isReadOnly={true}
              isDisabled={true}
            />
            <TextField
              fontSize= '0.75rem'
              fontWeight='400'
              value={contact}
              label='Mobile'
              isReadOnly={true}
              isDisabled={true}
            />
          </>
        )}
        <TextField
          fontSize= '0.75rem'
          fontWeight='400'
          value={company}
          label='What business is this feedback about?'
          isReadOnly={true}
          isDisabled={true}
        />
        <CategorySelector
          error={error}
          selectedCategories={selectedCategories}
          updateCategories={onUpdateCategories}
        />
        <TextAreaField
          fontSize= '0.75rem'
          fontWeight='400'
          value={details}
          label='Description'
          isReadOnly={true}
          isDisabled={true}
        />
        <TextAreaField
          fontSize= '0.75rem'
          fontWeight='400'
          value={currentNotes}
          label='Notes'
          onChange={handleNoteChange}
        />
        <Flex
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          wrap='nowrap'
          gap='2.5rem'
        >
          <Button
            width='4.75rem'
            height='2.65rem'
            variation='primary'
            type='submit'
            isDisabled={!detailsChanged}
            isLoading={loading}
          >
            Save
          </Button>
      </Flex>
     </Flex>
    </form>
  )
}

export default GrievanceForm
