import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { Button, Flex, Heading, Text } from '@aws-amplify/ui-react'
import ToastNotification from '../../components/toast-notification/ToastNotification'
import { SVGCaution } from '../../components/icons/SVGCaution'
import { COLOR } from '../../utils/Enum'

const RemoveUser = (props) => {
  const {
    request,
    email,
    onClose
  } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const onRemove = () => {
    setLoading(true)
    request.delete('users/delete', { data: {'email': email  } })
      .then((response) =>  {
        setLoading(false)
        onClose()
        ToastNotification({ message: `${email} has been deleted`})
      })
      .catch((error) => {
        setLoading(false)
        setError(error.message)
        Sentry.captureException(error)
      })
  }

  return (
    <Flex alignItems='top'>
      <SVGCaution size={92} color={COLOR.CHART_RED} />
      <div>
        <Heading
          level={2}
          style={{
            fontSize: '1.2rem',
            fontWeight: 400,
            marginBlockEnd: '1.5rem'
          }}
        >
          Delete user?
        </Heading>
        <Text>
          Are you sure you want to delete {email}?
        </Text>
        <Text style={{ marginBlockEnd: '5rem' }}>
          This cannot be undone.
        </Text>
        <Flex
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          wrap='nowrap'
          gap='2.5rem'
        >
          {error && (<Text>{error}</Text>)}
          <Button
            isDisabled={loading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            width='4.75rem'
            height='2.65rem'
            variation='primary'
            type='submit'
            isLoading={loading}
            onClick={onRemove}
          >
            Delete
          </Button>
        </Flex>
      </div>
    </Flex>
  )
}

export default RemoveUser
