import { useEffect, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import * as Sentry from '@sentry/react'
import { TextField, Button, Flex } from '@aws-amplify/ui-react'

import ToastNotification from '../../components/toast-notification/ToastNotification'

const ProfileDetailsForm = (props) => {
  const {
    currentName,
    currentEmail,
    getNonCachedUser,
    validateEmail,
    showResetPassword,
  } = props

  const [name, setName] = useState(currentName)
  const [email, setEmail] = useState(currentEmail)

  const [loading, setLoading] = useState(false)
  const [detailsChanged, setDetailsChanged] = useState(false)

  useEffect(
    () => {
      getNonCachedUser()
    },
    [getNonCachedUser]
  )

  const handleNameChange = (event) => {
    setName(event.target.value)
    setDetailsChanged(true)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
    setDetailsChanged(true)
  }

  const updateUserDetails = async (event) => {
    event.preventDefault()
    setLoading(true)
    const currentTimestamp = Date.now()

    const updatedDetails = {
      'name': name,
      'email': email,
      'updated_at': `${currentTimestamp}`
    }

    const user = await getNonCachedUser()

    await Auth.updateUserAttributes(user, updatedDetails).then(() => {
      setLoading(false)
      if (email !== currentEmail) return validateEmail(true)
      if (name !== currentName) return ToastNotification({ message: 'Your name has been updated' })
    }).catch((error) => {
      setLoading(false)
      Sentry.captureException(error)
    })
  }

  return (
    <form onSubmit={updateUserDetails}>
      <TextField
        fontSize='0.75rem'
        fontWeight='400'
        value={name}
        label='Your name'
        onChange={handleNameChange}
        isReadOnly={loading}
        isRequired={true}
        errorMessage='You need to enter a name'
        marginBottom='1.5rem'
      />
      <TextField
        fontSize='0.75rem'
        fontWeight='400'
        value={email}
        label='Email'
        onChange={handleEmailChange}
        isReadOnly={loading}
        isRequired={true}
        errorMessage='You need to enter an email'
        marginBottom='1.5rem'
      />
      <Button
        variation="link"
        onClick={() => showResetPassword(true)}
      >
        Reset password
      </Button>
      <Flex
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        wrap='nowrap'
        gap='2.5rem'
        marginTop='5rem'
      >
        <Button
          width='4.75rem'
          height='2.65rem'
          variation='primary'
          type='submit'
          isLoading={loading}
          isDisabled={!detailsChanged}
        >
          Save
        </Button>
     </Flex>
    </form>
  )
}

export default ProfileDetailsForm
