import { toast } from 'react-toastify'

import './ToastNotification.css'

const ToastNotification = (props) => {
  const { message } = props

  return (
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })

  )
}

export default ToastNotification
