import { CheckboxField, Flex } from '@aws-amplify/ui-react'
import { CATEGORIES } from '../../../utils/Enum'

const CategorySelector = (props) => {
  const {
    error,
    selectedCategories,
    updateCategories
  } = props

  return (
    <div>
      <label
        className='form-label'
        htmlFor='category'
        style={{
          fontSize: '0.75rem',
          fontWeight: '400',
        }}
      >
        Feedback category
      </label>
      <div
         style={{
          fontSize: '0.75rem',
          fontWeight: '300',
          marginBottom: '1rem'
        }}
      >
        (You can select more than one option)
      </div>
      <Flex
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        wrap='wrap'
        gap='.5rem'
        marginTop='.5rem'
      >
        {CATEGORIES.map(({ label, value }) => {
          return (
            <div key={value} className='grievance-form__checkbox'>
              <CheckboxField
                name='category'
                label={label}
                value={value}
                checked={selectedCategories.includes(value)}
                onChange={updateCategories}
              />
            </div>
          )
        })}
      </Flex>
      {error && <span className='grievance-form__error'>Please select at least one option.</span>}
    </div>
  )
}

export default CategorySelector

CategorySelector.defaultProps = {
  selectedCategories: []
}
