import { Text, Flex, Heading } from "@aws-amplify/ui-react"

const EmptyView = () => {
  return (
    <Flex
      direction='column'
      alignItems='center'
      justifyContent='center'
      gap='1rem'
      style={{ height: '30vh' }}
    >
      <Heading
        level={2}
        style={{
          fontSize: '1.5rem',
          fontWeight: 400,
        }}
      >
        No feedback has been received yet
      </Heading>
      <Text>When someone submits feedback it will show here.</Text>
    </Flex>
  )
}

export default EmptyView
